.pagination {
    .page {
        display: inline-block;
        background-color: var(--white);
        padding-inline-start: 0;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 3px;
        color: var(--primary);
        font-weight: bold;
        border: 1px solid var(--gray);
        margin-right: 10px;
        cursor: pointer;
        user-select: none;
        &:last-of-type {
            margin-right: 0;
        }

        &.page-selected {
            cursor: auto;
            background-color: var(--primary);
            color: var(--white);
        }
    }
}
