.DCV {
    display: grid; 
    grid-template-columns: 4fr 1fr; 
    gap: 25px;

    .data-row.group-list {
        grid-template-columns: unset;
    }

    .data-grouping-list {
        flex-wrap: wrap;
    }

    th {
        th {
            padding: 1em;
        }
    }
}

.DCV-Assurance {
    .measure-comment-link {
        color: blue;
        cursor: pointer;
    }
}

@media print {
    .DCV {
        grid-template-columns: auto;
    }
    .DCV .recharts-wrapper {
        height: 700px !important;
    }
    .DCV .using-graph {
        min-height: 700px !important;
        max-height: 900px !important;
    }
}